import {useEffect, useState} from 'react'
import Layout from '../components/Layout'
import Pagination from '../components/Pagination'
import EditTradingDetails from '../components/EditTradingDetails'
import { useParams } from 'react-router-dom'
import requestHit  from '../services/api.service'

function App() {
    
    const pageParams = useParams()
    const accountId = pageParams.id
    const url = 'admin/getTradingRules'
    const [ruleDetails, setRuleDetails] = useState(null);

    const detailsInfo = [{ "Account Type": "account_type" }, { "Duration" : "duration" }, {  "Created At": "createdAt" }];

    useEffect(()=>{
        if(accountId){
            requestHit.requestApiHit('post', `${url}`, {_id : accountId}).then((res) => {
                if (res.data) {
                    setRuleDetails(res.data[0]);
                } 
            })
        }

    }, [accountId])
    
    return (
        <Layout>
            {
                !accountId ? (
                    <main className="content">
                        <div className="container-fluid p-0">
                            <h1 className="h1 mb-3"><strong>Analytics</strong> Trading Rules</h1>
                            <div className="row">
                                <div className="col-12 col-lg-12 col-xxl-12 d-flex">
                                    <div className="card w-100">
                                        <div className="card-header">
                                            <h5 className="card-title mb-0">Rules</h5>
                                        </div>
                                        <div className='table-responsive'>
                                            <Pagination header={detailsInfo} url={ url } pageFor={'trading'} detailsNeed={true}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                ) : (
                    <main className="content">
                        <div className="container-fluid p-0">
                            <div className="row">
                                <EditTradingDetails data={ruleDetails}/>
                            </div>
                        </div>
                    </main>
                )
            }
        </Layout>
    );
}

export default App;
