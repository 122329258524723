import { useEffect, useState } from "react";
import Pagination from "../components/Pagination";
import EditUpgradeChallenge from "../components/EditUpgradeChallenge";
import { useParams } from "react-router-dom";
import requestHit from "../services/api.service";
import Layout from "../components/Layout";

function App() {
  const pageParams = useParams();
  const paramId = pageParams.id;
  const url = "admin/getUpgradeRequests";
  const [ruleDetails, setRuleDetails] = useState({});

  const detailsInfo = [
    { Name: "name" },
    { "Login ID": "login" },
    { Phase: "phase" },
    { "Phase status": "phaseStatus" },
    // { "Phase status": "phase_status" },
    { "Service By": "server" },
    { Platform: "platform" },
    { "Account Type": "account_type" },
  ];

  useEffect(() => {
    if (paramId) {
      requestHit
        .requestApiHit("post", `${url}`, { _id: paramId })
        .then((res) => {
          if (res.data) {
            setRuleDetails(res.data[0]);
          }
        });
    }
  }, [paramId]);

  return (
    <Layout>
      {!paramId ? (
        <main className="content">
          <div className="container-fluid p-0">
            <h1 className="h1 mb-3">
              <strong>Analytics</strong> Upgrade Challenge
            </h1>
            <div className="row">
              <div className="col-12 col-lg-12 col-xxl-12 d-flex">
                <div className="card w-100">
                  <div className="card-header">
                    <h5 className="card-title mb-0">Rules</h5>
                  </div>
                  <div className="table-responsive">
                    <Pagination
                      header={detailsInfo}
                      url={url}
                      pageFor={"upgradeChallenge"}
                      detailsNeed={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      ) : (
        <main className="content">
          <div className="container-fluid p-0">
            <div className="row">
              <EditUpgradeChallenge
                data={ruleDetails}
                pageFor={"upgradeChallenge"}
              />
            </div>
          </div>
        </main>
      )}
    </Layout>
  );
}

export default App;
