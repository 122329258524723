import {useEffect, useState} from 'react'
import Layout from '../components/Layout'
import Pagination from '../components/Pagination'
import EditKycVerificationDetail from '../components/EditKycVerificationDetail'
import { useParams } from 'react-router-dom'
import requestHit  from '../services/api.service'

function App() {
    
    const pageParams = useParams()
    const accountId = pageParams.id
    const url = 'admin/kycList'
    const [accountDetails, setAccountDetails] = useState([]);

    const detailsInfo = [{"First Name":"first_name"}, {"Last Name": "last_name"}, {"City" :  "city"}, {"Contact" : "contact"}, {"Country": "country_name"}, {"DOB": "dob"} , {"Mail" : "mail_id"}, {"Status": "status"}];

    useEffect(()=>{
        if(accountId){
            requestHit.requestApiHit('post', `${url}`, {_id : accountId}).then((res) => {
                if (res.data) {
                    setAccountDetails(res.data[0]);
                } 
            })
        }
    }, [accountId])
    
    return (
       <Layout>
            {
                !accountId ? (
                    <main className="content">
                        <div className="container-fluid p-0">
                            <h1 className="h1 mb-3"><strong>KYC</strong> History List</h1>
                            <div className="row">
                                <div className="col-12 col-lg-12 col-xxl-12 d-flex">
                                    <div className="card w-100">
                                        <div className="card-header">
                                            <h5 className="card-title mb-0"> KYC History</h5>
                                        </div>
                                        <div className='table-responsive'>
                                            <Pagination header={detailsInfo} url={ url } pageFor={'kycVerification'} detailsNeed={true} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                ) : (
                    <main className="content">
                        <div className="container-fluid p-0">
                            <div className="row">
                                <EditKycVerificationDetail data={accountDetails} pageFor={'kycVerification'} />
                            </div>
                        </div>
                    </main>
                )
            }
        </Layout>
    );
}

export default App;
