import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { toast } from 'react-toastify';
import userService from "../services/api.service";
const Logo = require('../asset/img/logo-467x100.png');

function App() {
    let navigate = useNavigate();   
    const [payloads,setPayloads] = useState({});  
    const handleChange=(e)=>{
        e.preventDefault();
        let name = e.target.name;
        let value = e.target.value;
        setPayloads((previousValue)=>{return {...previousValue,[name]:value}})
    }
    const userLogin =async()=>{        
        if(!payloads || !payloads.email  || !payloads.password ){
            setPayloads({});
            toast.error("Required All Fields!");
        }else{
            try {
                let response = await userService.userLogin(payloads);
                if(response.status){
                    localStorage.setItem("token",response.token)                
                    toast.success("Login Successfully!");  
                    navigate('/dashboard')
                }else{  
                    toast.error("something went to wrong");
                    setPayloads({});                   
                }     
            } catch (error) {
                toast.error("something went to wrong");
                setPayloads({});                
            }
        }        
    }

    return (
        <>
            <main className="bg-color d-flex w-100">
                <div className="container d-flex flex-column">
                    <div className="row vh-100">
                        <div className="col-sm-10 col-md-8 col-lg-6 mx-auto d-table h-100">
                            <div className="d-table-cell align-middle">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="m-sm-4">
                                            <div className="text-center">
                                                <img src={Logo} alt="MPF Admin" className="img-fluid "/>
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label">Email</label>
                                                <input className="form-control form-control-lg" type="email" value={payloads.email} onChange={(e)=>{handleChange(e)}} name="email" placeholder="Enter your email" />
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label">Password</label>
                                                <input className="form-control form-control-lg" type="password"  value={payloads.password}  onChange={(e)=>{handleChange(e)}} name="password" placeholder="Enter your password" />
                                                <small>
                                                    <Link href="index.html">Forgot password?</Link>
                                                </small>
                                            </div>
                                            <div>
                                                <label className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="remember-me" name="remember-me" checked onChange={(e)=>{handleChange(e)}} />
                                                    <span className="form-check-label">
                                                    Remember me next time
                                                    </span>
                                                </label>
                                            </div>
                                            <div className="text-center mt-3">
                                                <button type="button" onClick={userLogin} className="btn primary-btn">Sign in</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export default App;
