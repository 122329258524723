import React, { useState } from 'react';
import Sidebar from './Sidebar.js';
import Navbar from './Navbar.js';
import Footer from './Footer.js';
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

function ToastMsg(props) {
    return (
        <div>
            <ToastContainer />
        </div>
    );
}

export default ToastMsg;