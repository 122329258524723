// Dependencies
import React, { useEffect, useState } from "react";
import "react-tabs/style/react-tabs.css";
import requestHit from "../services/api.service";
import { toast } from "react-toastify";
import ToastMsg from '../components/ToastMsg';
import { useNavigate } from "react-router-dom";

// Pagination component
function App({ data }) {
  const navigate = useNavigate();
  const [record, setRecord] = useState({});

  useEffect(() => {
    (() => {
      let record = {
        _id: data._id,
        coupon: data.coupon,
        type: data.type,
        description: data.description,
        discount: data.discount,
        status: data.status,
        isDeleted: data.isDeleted
      };
      setRecord(record);
    })();
  }, [data]);

  const handleCommon = (event) => {
    let names = event.target.name;
    setRecord((previous) => {
      return { ...previous, [names]: event.target.value };
    });
  };

  const saveRecord = () => {
    let saveUrl = record._id ? "admin/updateCoupon" : "admin/addCoupon";

    requestHit.requestApiHit("post", saveUrl, record).then((res) => {
      if (res.status) {
        toast.success(res.message);
        setTimeout(() => {
          navigate(`/coupon`);
        }, 1000);
      } else {
        toast.error(res.message);
      }
    });
  };

  const deleteRecord = () => {
    requestHit
      .requestApiHit("post", `admin/deleteCoupon`, { _id: record._id })
      .then((res) => {
        if (res.status) {
          toast.success(res.message);
          setTimeout(() => {
            navigate(`/coupon`);
          }, 1000);
        } else {
          toast.error(res.message);
        }
      });
  };

  return (
      <div className="container-fluid bg-white p-4">
         <ToastMsg>
         </ToastMsg>
        <div className="row">
          <div className="col-md-12 mb-4">
            <h2>{record._id ? "Edit" : "Add New"} Coupon Details</h2>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-md-2"> Coupon Code </div>
          <div className="col-md-6">
            <input
              type="text"
              name="coupon"
              value={record.coupon}
              className="w-100 h-100"
              onChange={(e) => {
                handleCommon(e);
              }}
            />
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-md-2"> Type </div>
          <div className="col-md-6">
            <select name='type' value={record.type}  className='w-100 p-2' onChange={e => { handleCommon(e) }}>
              <option value="percentage">Percentage</option>
              <option value="flat">Flat</option>
            </select>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-md-2">Description</div>
          <div className="col-md-6">
            <textarea
              type="text"
              name="description"
              value={record.description}
              className="w-100 h-100"
              onChange={(e) => {
                handleCommon(e);
              }}
            />
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-md-2">Discount</div>
          <div className="col-md-6">
            <input
              type="text"
              name="discount"
              value={record.discount}
              className="w-100 h-100"
              onChange={(e) => {
                handleCommon(e);
              }}
            ></input>
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-md-2">Status</div>
          <div className="col-md-6">
            <select name='status' value={record.status}  className='w-100 p-2' onChange={e => { handleCommon(e) }}>
              <option value={1}>Active</option>
              <option value={0}>Deactive</option>
            </select>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <button onClick={() => saveRecord()} className="btn btn-primary mt-4">
              Submit
            </button>
            {record._id && (
              <button
                onClick={() => deleteRecord()}
                className="btn btn-primary mt-4 mx-4"
              >
                Delete
              </button>
            )}
          </div>
        </div>
      </div>
  );
}
export default App;
