// Dependencies
import React, { useEffect, useState } from 'react'
import 'react-tabs/style/react-tabs.css';
import requestHit  from '../services/api.service'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify';

// Pagination component
function App({data,pageFor}) {  
    const [record, setRecord] = useState({})    
    const navigate = useNavigate();

    useEffect(()=>{
        (()=>{
            let record = {
                _id : data._id,
                first_name: data.first_name,
                email: data.email,
                phonenumber: data.phonenumber,
                acc_no: data.acc_no,
                ifsc_code: data.ifsc_code,
                micr_code: data.micr_code,
                amount: data.amount,
                payment_status: data.payment_status,


            }
            setRecord(record);
        })()
    }, [data])

    const handleCommon = event => {        
        let names = event.target.name
        setRecord(previous => {
            if(record.payment_status === 2 && record.reason === ""){
                toast.error("Reject Reason Required");
            }
            return { ...previous, [names]: event.target.value }
        })  
    }

    const saveRecord = ()=>{
        let saveUrl = record._id ? 'admin/approvePayout' : 'admin/addCms'
        requestHit.requestApiHit('post', saveUrl, record).then((res) => {
            if(res.status){
                toast.success(res.message);
                navigate(`/${pageFor}` )
            }else{
                toast.error(res.message);
                navigate(`/${pageFor}` )
            }
        })
    }

    // const deleteRecord = ()=>{
    //     requestHit.requestApiHit('post', `admin/deletePayouts`, {_id: record._id}).then((res) => {
    //         if(res.status){
    //             toast.success(res.message);
    //         }else{
    //             toast.error(res.message);
    //         }
    //     })
    // }

    return (
        <div className='container-fluid card p-4'>

            <div className='row'>
                <div className='col-md-12 mb-4 '><h2 class='text-white'> Payout</h2></div>                
            </div>
            <div className='row mb-4'>
                {/* <div className='col-md-2'>Answer</div> */}
                <div className='col-md-2'>
                    <select name='payment_status' value={record.payment_status}  className='w-100 p-2' onChange={e => { handleCommon(e) }}>
                        <option value={0}>Pending</option>
                        <option value={1}>Approve</option>
                        <option value={2}>Reject</option>
                    </select>
                </div>
            </div>
            <div className='row mb-4'>
            <div className='label'> Reason </div>
                <div className='col-md-6'>
                    <input type='text' name='reason' value={record.reason} className='w-100 p-2' onChange={e => { handleCommon(e) }} />
                </div>
            </div>

            <div className='row'>
                <div className='col-12'>
                    <button onClick={()=> saveRecord() } className='btn-1 mt-4'>Submit</button>
                    {/* { record._id && ( <button onClick={()=> deleteRecord() } className='btn-1 mt-4 mx-4'>Delete</button> )
                    } */}
                </div>
            </div>
        </div>
    );
}
export default App
