import axios from 'axios';
import http_common from '../http_common';
    
    const requestApiHit = (method, url, payloads = {}) => {
        
        return new Promise(async (resolve, reject)=>{
            try {
                let temptoken = await localStorage.getItem('token')
                const config = {
                    headers: {
                    Authorization: `Bearer ${temptoken}`
                    }
                }
            
                if (method === 'get') {
                    axios.get(`${http_common}${url}`, config).then((response)=>{
                        resolve(response.data);
                    }).catch((error)=>{
                        console.log(error);
                        // localStorage.clear()
                        // window.location('/');
                    })
                } else {
                    axios.post(`${http_common}${url}`, payloads, config).then((response)=>{
                        resolve(response.data);
                    }).catch((error)=>{
                        // localStorage.clear()
                        // window.location.href ='/';
                    })
                }
            } catch (error) {
                reject({
                    status: false,
                    message: error.message ? error.message : 'Some error occured',
                    data: null
                });
            }
        })
    }  
 
    const userLogin = async(data)=>{
        try {
            let response = await axios.post(`${http_common}admin/login`, data)
            return response.data        
        } catch (error) {
            return {
                status : false,
                message : error.message ? error.message : "Some error occured",
                data : null
            }
        }
    }

    const userRegister = async(data)=>{
        try {
            console.log("responsebeforeregister",data);
            let response = await axios.post(`${http_common}user/register`, data)
            console.log("response",response.data);
            return response.data        
        } catch (error) {
            return {
                status : false,
                message : error.message ? error.message : "Some error occured",
                data : null
            }
        }
    }

    const forgotPassword = async(data)=>{
        console.log("responsebeforeforget-password",data);
        try {
            let response = await axios.post(`${http_common}user/forget-password`, data)
            return response.data        
        } catch (error) {
            return {
                status : false,
                message : error.message ? error.message : "Some error occured",
                data : null
            }
        }
    }

    const resetPassword = async(data)=>{
        try {
            console.log("responsebeforereset-password",data);
            let response = await axios.post(`${http_common}user/reset-password`, data)
            return response.data        
        } catch (error) {
            return {
                status : false,
                message : error.message ? error.message : "Some error occured",
                data : null
            }
        }
    }


    const verifyAccount = async(data)=>{
        try {
            console.log("responsebeforeverify-account",data);
            let response = await axios.post(`${http_common}user/verify-account`, data)
            return response.data        
        } catch (error) {
            return {
                status : false,
                message : error.message ? error.message : "Some error occured",
                data : null
            }
        }
    }
    const getUserList = async(data)=>{
        try {
            let response = await axios.post(`${http_common}admin/getUserList`, data)
            return response.data        
        } catch (error) {
            return {
                status : false,
                message : error.message ? error.message : "Some error occured",
                data : null
            }
        }
    }
    const createCms = async(data)=>{
        try {
            let response = await axios.post(`${http_common}admin/updateCms`, data)
            return response.data        
        } catch (error) {
            return {
                status : false,
                message : error.message ? error.message : "Some error occured",
                data : null
            }
        }
    }
    const updatePrivacy = async(data)=>{
        try {
            let response = await axios.put(`${http_common}admin/updateCms`, data)
            return response.data        
        } catch (error) {
            return {
                status : false,
                message : error.message ? error.message : "Some error occured",
                data : null
            }
        }
    }
    const getCms = async(data)=>{
        try {
            let response = await axios.get(`${http_common}admin/getCms/${data}`, data)
            return response.data        
        } catch (error) {
            return {
                status : false,
                message : error.message ? error.message : "Some error occured",
                data : null
            }
        }
    }

const requestHit =  {
    requestApiHit, userLogin,userRegister,forgotPassword,resetPassword,verifyAccount, getUserList,
    createCms, updatePrivacy, getCms
 }

 export default requestHit