import { Link } from "react-router-dom";
const Logo = require("../asset/img/logo-467x100.png");

function Sidebar(menuActive) {
  const page =
    window.location.href.split("/")[window.location.href.split("/").length - 1];

  return (
    <nav
      id="sidebar"
      className={
        menuActive.menuActive
          ? "sidebar js-sidebar bg-color-primary collapsed"
          : "sidebar js-sidebar bg-color-primary"
      }
    >
      <div className="sidebar-content js-simplebar">
        <a className="sidebar-brand" href="index.html">
          <span className="align-middle">
            <img src={Logo} alt="logo" className="img-fluid" />
          </span>
        </a>

        <ul className="sidebar-nav">
          <li className="sidebar-header">Pages</li>

          <li
            className={`sidebar-item ${page === "dashboard" ? "active" : ""}`}
          >
            <Link className="sidebar-link" to="/dashboard">
              <i className="align-middle" data-feather="sliders"></i>{" "}
              <span className="align-middle">Dashboard</span>
            </Link>
          </li>

          <li className={`sidebar-item ${page === "users" ? "active" : ""}`}>
            <Link className="sidebar-link" to="/users">
              <i className="align-middle" data-feather="log-in"></i>{" "}
              <span className="align-middle">Users</span>
            </Link>
          </li>
          <li className={`sidebar-item ${page === "trading" ? "active" : ""}`}>
            <Link className="sidebar-link" to="/trading">
              <i className="align-middle" data-feather="log-in"></i>{" "}
              <span className="align-middle">Trading Rules</span>
            </Link>
          </li>
          <li
            className={`sidebar-item ${
              page === "payInHistory" ? "active" : ""
            }`}
          >
            <Link className="sidebar-link" to="/payInHistory">
              <i className="align-middle" data-feather="log-in"></i>{" "}
              <span className="align-middle">Pay In History</span>
            </Link>
          </li>
          <li
            className={`sidebar-item ${page === "payHistory" ? "active" : ""}`}
          >
            <Link className="sidebar-link" to="/payHistory">
              <i className="align-middle" data-feather="log-in"></i>{" "}
              <span className="align-middle">Pay Out History</span>
            </Link>
          </li>
          <li
            className={`sidebar-item ${
              page === "upgradeChallenge" ? "active" : ""
            }`}
          >
            <Link className="sidebar-link" to="/upgradeChallenge">
              <i className="align-middle" data-feather="log-in"></i>{" "}
              <span className="align-middle">Upgrade Challenge</span>
            </Link>
          </li>
          <li
            className={`sidebar-item ${page === "Challanges" ? "active" : ""}`}
          >
            <Link className="sidebar-link" to="/challange">
              <i className="align-middle" data-feather="log-in"></i>{" "}
              <span className="align-middle">User Challanges</span>
            </Link>
          </li>
          <li
            className={`sidebar-item ${
              page === "kycVerification" ? "active" : ""
            }`}
          >
            <Link className="sidebar-link" to="/kycVerification">
              <i className="align-middle" data-feather="log-in"></i>{" "}
              <span className="align-middle">KYC Verification</span>
            </Link>
          </li>
          <li className={`sidebar-item ${page === "coupon" ? "active" : ""}`}>
            <Link className="sidebar-link" to="/coupon">
              <i className="align-middle" data-feather="log-in"></i>{" "}
              <span className="align-middle">Coupon</span>
            </Link>
          </li>
          <li
            className={`sidebar-item ${page === "certificate" ? "active" : ""}`}
          >
            <Link className="sidebar-link" to="/certificate">
              <i className="align-middle" data-feather="log-in"></i>{" "}
              <span className="align-middle">Certificate</span>
            </Link>
          </li>
          <li
            className={`sidebar-item ${
              page === "configuration" ? "active" : ""
            }`}
          >
            <Link className="sidebar-link" to="/configuration">
              <i className="align-middle" data-feather="log-in"></i>{" "}
              <span className="align-middle">Configuration</span>
            </Link>
          </li>
          {/* <li className="sidebar-header">Cms</li> */}
          {/* <li
            className={`sidebar-item ${
              page === "privacyPolicy" ? "active" : ""
            }`}
          >
            <Link className="sidebar-link" to="/privacyPolicy">
              <i className="align-middle" data-feather="log-in"></i>{" "}
              <span className="align-middle">Privacy Policy</span>
            </Link>
          </li>
          <li
            className={`sidebar-item ${
              page === "termsCondition" ? "active" : ""
            }`}
          >
            <Link className="sidebar-link" to="/termsCondition">
              <i className="align-middle" data-feather="log-in"></i>{" "}
              <span className="align-middle">Terms and Condition</span>
            </Link>
          </li> */}
          <li className={`sidebar-item ${page === "cms" ? "active" : ""}`}>
            <Link className="sidebar-link" to="/cms">
              <i className="align-middle" data-feather="log-in"></i>{" "}
              <span className="align-middle">CMS</span>
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default Sidebar;
